@import url("https://fonts.googleapis.com/css2?family=Lato&family=Montserrat&family=Ubuntu:wght@700&display=swap");

@font-face {
    font-family: "pbgMtavruli";
    src: local("CustomFont"),
        url("./assets/fonts/pbg_mtavruli.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

/* :root {
  --primary-color: #;
  --secondary-color: #;
  --text-color: #;
} */

body:lang(ka) {
    font-family: "pbgMtavruli", sans-serif;
}

body:lang(en) {
    font-family: "Montserrat", sans-serif;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    background-color: #d1d1d1;
}

/* media query */
@media screen and (min-width: 768px) {
    #carrier-img {
        transition: 0.5s;
    }
    /* keychain cursor for truck */
    #carrier-img:hover {
        transform: scale(1.05);
        cursor: url("./assets/imgs/keychain.png"), auto;
    }
}
